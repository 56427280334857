import { Children, FC, ReactElement, useEffect } from 'react';

import { useTracking } from './hooks/useTracking';

export type ViewComponentProps = {
  scope?: string;
  /** label of the child component, tracking event will be sent as ${variant} - ${label} */
  label: string;
  /** variant of the child component, tracking event will be sent as ${variant} - ${label}*/
  variant?: string;
  /** name of the child component to be tracked, should be equal to the name of the child */
  type: string;
  children: ReactElement;
  additionalInfo?: string;
};

/**
 * Sents a tracking view_component event when child component is being rendered.
 *
 * Note: If reactStrictMode is set to true in your development environment, the tracking provider events will always fire twice. This won't happen on the acceptance or production environment.
 */

export const TrackViewComponent: FC<ViewComponentProps> = ({
  scope,
  children,
  variant = 'default',
  type,
  label,
  additionalInfo,
}) => {
  const { trackViewComponent } = useTracking(scope);

  Children.only(children);

  useEffect(() => {
    trackViewComponent({ label, type, variant, additionalInfo });
  }, [label, type, variant, additionalInfo, trackViewComponent]);

  return <>{children}</>;
};
