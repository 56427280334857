import { useApplication } from '@common/application';

export const useAddressFinderQueryStringDefaultValues = () => {
  const { searchParams } = useApplication();
  return {
    houseNumber: searchParams.get('houseNumber') || '',
    houseNumberSuffix: searchParams.get('houseNumberSuffix') || '',
    postalCode: searchParams.get('postalCode')?.toUpperCase() || '',
  };
};
