import { FC } from 'react';

import { NotificationBox } from '@sparky';
import { NotificationBoxProps } from '@sparky/types';
import { TrackViewComponent } from '@tracking';

type TrackedNotificationBoxPropsWithLabel = NotificationBoxProps & { label: string };
type TrackedNotificationBoxPropsWithTitle = NotificationBoxProps & {
  title: NonNullable<NotificationBoxProps['title']>;
  label?: never;
};

type TrackedNotificationBoxProps = TrackedNotificationBoxPropsWithLabel | TrackedNotificationBoxPropsWithTitle;

export const TrackedNotificationBox: FC<TrackedNotificationBoxProps> = ({ label, ...notificationBoxProps }) => {
  return (
    <TrackViewComponent
      type="NotificationBox"
      label={label || notificationBoxProps.title || 'unknown'}
      variant={notificationBoxProps.variant}>
      <NotificationBox {...notificationBoxProps} />
    </TrackViewComponent>
  );
};
